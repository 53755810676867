import {
  Device,
  DeviceCorrection,
  createDeviceCorrection,
  initialCorrection,
  useCorrection,
  useDevices,
} from "api/iba";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  calibrationHasGlutenGOSTCorrection,
  calibrationHasGlutenISOCorrection,
  calibrationHasOilCorrection,
  calibrations,
  getCalibrationTextId,
  rapeseed,
} from "lib/calibrations";
import Checkbox from "components/Checkbox";
import Collapse from "components/Collapse";
import FormSelect from "components/FormSelect";
import IBACalculator from "./IBACalculator";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import { classes } from "lib/helpers";
import errorImage from "images/error.svg";
import popupArrow from "images/popupArrow.svg";
import styles from "./IBA.module.scss";
import { useIntl } from "react-intl";
import useMessage from "lib/message";
import { useSession } from "lib/session";
import { v4 as uuidv4 } from "uuid";

function round(num: number) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

function CorrectionRow({
  value,
  onValue,
  areaValue,
  addArea,
  isGluten,
  limit,
  children,
}: {
  value: number | undefined;
  onValue: (value: number) => void;
  areaValue?: number | undefined;
  addArea?: boolean;
  isGluten?: boolean;
  children?: ReactNode;
  limit: number;
}) {
  const { handheld_iba_enabled } = useSession();
  return (
    <>
      <label className={classes(isGluten && styles.gluten)}>{children}</label>
      {isGluten ? null : <input value={areaValue || 0} disabled />}
      <input
        type="number"
        disabled={!handheld_iba_enabled}
        required
        min={-limit}
        max={limit}
        step={0.1}
        value={value || 0}
        onChange={(e) => {
          if (e.target.value === "") {
            onValue(0);
          }
          const value = e.target.valueAsNumber;
          if (!isNaN(value)) {
            onValue(value);
          }
        }}
      />
      <input
        value={round((value || 0) + (addArea ? areaValue || 0 : 0))}
        disabled
      />
    </>
  );
}

function CorrectionForm({
  calibration,
  device,
  correction: c,
  onCorrection,
}: {
  calibration: string;
  device: Device;
  correction: DeviceCorrection;
  onCorrection: (c: DeviceCorrection) => void;
}) {
  const hasOil = calibrationHasOilCorrection(calibration);
  const hasGlutenISO = calibrationHasGlutenISOCorrection(calibration);
  const hasGlutenGOST = calibrationHasGlutenGOSTCorrection(calibration);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const params = useMemo(
    () => ({ calibration, device: device.serial_number }),
    [calibration, device]
  );
  const [correction, loading] = useCorrection(params);
  const { handheld_iba_enabled } = useSession();

  const area = correction?.area_correction;

  const limit = useMemo(() => {
    if (device.device_model === "A2") {
      return 5;
    } else if (calibration === rapeseed) {
      return 3;
    } else {
      return 2;
    }
  }, [calibration, device.device_model]);

  const oilLimit = useMemo(() => {
    if (device.device_model === "A2") {
      return 5;
    } else if (calibration === rapeseed) {
      return 5;
    } else {
      return 2;
    }
  }, [calibration, device.device_model]);

  useEffect(() => {
    if (correction?.device_correction) {
      onCorrection(correction.device_correction);
    } else {
      onCorrection(initialCorrection);
    }
  }, [correction?.device_correction, onCorrection]);

  const setField = useCallback(
    <K extends keyof DeviceCorrection>(key: K, value: DeviceCorrection[K]) =>
      onCorrection({ ...c, [key]: value }),
    [c, onCorrection]
  );

  const useNewIbaValues = (
    newIbaProtein: number | undefined,
    newIbaMoisture: number | undefined,
    newIbaOil: number | undefined
  ) => {
    let obj = Object.assign({}, c);

    if (newIbaProtein !== undefined) {
      obj["protein_offset"] = Math.round(newIbaProtein * 10) / 10;
    }

    if (newIbaMoisture !== undefined) {
      obj["moisture_offset"] = Math.round(newIbaMoisture * 10) / 10;
    }

    if (newIbaOil !== undefined) {
      obj["oil_offset"] = Math.round(newIbaOil * 10) / 10;
    }

    onCorrection(obj);
  };

  return (
    <>
      <fieldset className={styles.main} disabled={loading}>
        <div className={styles.corrections}>
          <div className={styles.addNewIBA}>
            <T id="iba.addNewIBA" />
          </div>
          <label className={styles.header}>
            <T id="iba.country" />
          </label>
          <label className={styles.header}>
            <T id="iba.device" />
          </label>
          <label className={classes(styles.header, styles.total)}>
            <T id="iba.total" />
          </label>

          <CorrectionRow
            value={c.protein_offset}
            onValue={(value) => setField("protein_offset", value)}
            areaValue={area?.protein_offset}
            addArea={c.add_area_correction}
            limit={limit}
          >
            <T id="measurement.protein" /> (%)
          </CorrectionRow>

          <CorrectionRow
            value={c.moisture_offset}
            onValue={(value) => setField("moisture_offset", value)}
            areaValue={area?.moisture_offset}
            addArea={c.add_area_correction}
            limit={limit}
          >
            <T id="measurement.moisture" /> (%)
          </CorrectionRow>

          {hasOil ? (
            <CorrectionRow
              value={c.oil_offset}
              onValue={(value) => setField("oil_offset", value)}
              areaValue={area?.oil_offset}
              addArea={c.add_area_correction}
              limit={oilLimit}
            >
              <T id="measurement.oil" /> (%)
            </CorrectionRow>
          ) : null}

          {hasGlutenISO ? (
            <CorrectionRow
              value={c.gluten_iso_offset}
              onValue={(value) => setField("gluten_iso_offset", value)}
              isGluten
              limit={5}
            >
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.iso" /> (%)
            </CorrectionRow>
          ) : null}

          {hasGlutenGOST ? (
            <CorrectionRow
              value={c.gluten_gost_offset}
              onValue={(value) => setField("gluten_gost_offset", value)}
              isGluten
              limit={5}
            >
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.gost" /> (%)
            </CorrectionRow>
          ) : null}
        </div>

        <div className={styles.actions}>
          <button
            type="submit"
            disabled={!handheld_iba_enabled}
            className="cta medium secondary"
            onClick={(e) => {
              onCorrection(initialCorrection);
            }}
          >
            <T id="reset" />
          </button>
          <Checkbox
            type="checkbox"
            disabled={!handheld_iba_enabled}
            checked={!c.add_area_correction}
            onChecked={(value) => setField("add_area_correction", !value)}
          >
            <T id="iba.disableCountryOffset" />
          </Checkbox>
          <button
            disabled={!handheld_iba_enabled}
            type="submit"
            className="cta medium"
            ref={submitButtonRef}
          >
            <T id="save" />
          </button>
        </div>

        <CalculatorCollapse
          onClickButton={useNewIbaValues}
          calibration={calibration}
          correction={c}
          submitButtonRef={submitButtonRef}
        />
      </fieldset>
    </>
  );
}

function IBAFormLoaded({ devices }: { devices: Device[] }) {
  const intl = useIntl();
  const sendMessage = useMessage();
  const [calibration, setCalibration] = useState<string>(calibrations[0]);
  const [device, setDevice] = useState(devices[0]);
  const [correction, setCorrection] = useState(initialCorrection);
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <h2 className={styles.instructionsTitle}>
        <T id="iba.instructions" />:
      </h2>
      <ol className={styles.instructionsList}>
        <li>
          <T id="iba.instructions1" />
        </li>
        <li>
          <T id="iba.instructions2" />
        </li>
        <li>
          <T id="iba.instructions3" />
        </li>
        <li>
          <T id="iba.instructions4" />
        </li>
        <li>
          <T id="iba.instructions5" />
        </li>
      </ol>
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          setSubmitting(true);
          await createDeviceCorrection({
            ...correction,
            uuid: uuidv4(),
            calibration,
            device: device.serial_number,
          });
          sendMessage(<T id="iba.success" />);
          setSubmitting(false);
        }}
      >
        <fieldset disabled={submitting}>
          <FormSelect
            options={devices}
            value={device}
            onValue={setDevice}
            render={(d) => String(d.serial_number)}
          >
            <T id="iba.device" />
          </FormSelect>
          <FormSelect
            options={calibrations}
            value={calibration}
            onValue={setCalibration}
            render={(c) => intl.formatMessage({ id: getCalibrationTextId(c) })}
          >
            <T id="measurement.species" />
          </FormSelect>
          <CorrectionForm
            calibration={calibration}
            device={device}
            correction={correction}
            onCorrection={setCorrection}
          />
        </fieldset>
      </form>
    </>
  );
}

function CalculatorCollapse({
  onClickButton,
  correction,
  calibration,
  submitButtonRef,
}: {
  onClickButton: (
    newIbaProtein: number | undefined,
    newIbaMoisture: number | undefined,
    newIbaOil: number | undefined
  ) => void;
  correction: DeviceCorrection | null | undefined;
  calibration: string;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={classes(
          "ctaLink medium",
          styles.linkButton,
          open && styles.open
        )}
        onClick={() => setOpen(!open)}
      >
        <T id={"iba.calculator"} />
        <img src={popupArrow} alt="Calculator" />
      </button>

      <Collapse
        className={open ? styles.collapseCalculator : undefined}
        open={open}
      >
        <IBACalculator
          onClickButton={onClickButton}
          calibration={calibration}
          correction={correction}
          submitButtonRef={submitButtonRef}
        />
      </Collapse>
    </>
  );
}

function IBAForm() {
  const [devices] = useDevices();
  return devices ? (
    devices.length > 0 ? (
      <IBAFormLoaded devices={devices} />
    ) : (
      <p className={styles.error}>
        <img src={errorImage} alt="error" />
        <T id="iba.noDevices" />
      </p>
    )
  ) : (
    <Spinner />
  );
}

function IBA() {
  return (
    <div className="dashboardSection">
      <h1>
        <T id="iba" />
      </h1>
      <div className={classes("dashboardTop", styles.description)}>
        <T id="iba.description" />
      </div>
      <IBAForm />
    </div>
  );
}

export default IBA;
